var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"1120","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Detailed status ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',{attrs:{"justify":"center"}},[_c('EntitySheetDialog',{attrs:{"entitySheetId":_vm.entitySheetId,"entityDialog":_vm.entityDialog},on:{"handleCloseEntitySheetDialog":_vm.handleCloseEntitySheetDialog}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.entityListHeaders,"items":_vm.entityList,"group-by":"quantity","show-group-by":"","items-per-page":-1,"hide-default-footer":""},on:{"click:row":_vm.handleOpenEntitySheetDialog},scopedSlots:_vm._u([{key:"group.header",fn:function({
                  group,
                  headers,
                  toggle,
                  isOpen,
                  items,
                }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(items[0].reference_number)+" "+_vm._s(items[0].name)+" - "+_vm._s(items[0].quantityUnit)+" - "+_vm._s(items.length)+" pcs ")],1)]}},{key:"item.status",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("ADJUSTMENT.status_" + item.status))+" ")])]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }