<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1120" persistent scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Detailed status
        </v-card-title>

        <v-card-text class="mt-3">
          <v-row justify="center">
            <EntitySheetDialog
              :entitySheetId="entitySheetId"
              :entityDialog="entityDialog"
              @handleCloseEntitySheetDialog="handleCloseEntitySheetDialog"
            >
            </EntitySheetDialog>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="entityListHeaders"
                :items="entityList"
                class="elevation-1"
                group-by="quantity"
                show-group-by
                :items-per-page="-1"
                hide-default-footer
                @click:row="handleOpenEntitySheetDialog"
              >
                <template
                  v-slot:group.header="{
                    group,
                    headers,
                    toggle,
                    isOpen,
                    items,
                  }"
                >
                  <td :colspan="headers.length">
                    <v-btn
                      @click="toggle"
                      small
                      icon
                      :ref="group"
                      :data-open="isOpen"
                    >
                      <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                    {{ items[0].reference_number }} {{ items[0].name }} -
                    {{ items[0].quantityUnit }} - {{ items.length }} pcs
                  </td>
                </template>
                <!-- <template v-slot:item.quantityUnit="{ item }">
                  <span>
                    {{ item.quantity }}
                    {{ selectedStockList.quantityUnit }}</span
                  >
                </template> -->
                <template v-slot:item.status="{ item }">
                  <span>
                    {{ $t("ADJUSTMENT.status_" + item.status) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseModalForm">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import EntitySheetDialog from "@/view/components/EntitySheetDialog";

export default {
  name: "SODetailedDialog",
  components: { EntitySheetDialog },
  props: ["formModel", "dialog"],

  data() {
    return {
      urls: URL,
      entityList: [],
      entityListHeaders: [
        {
          text: "Entity ID",
          value: "item_entity_id",
          groupable: false,
        },
        {
          text: "Serial number",
          value: "serial_number",
          groupable: false,
        },
        {
          text: "Reference",
          value: "reference_number",
          groupable: false,
        },

        {
          text: "Item name",
          value: "name",
          groupable: false,
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Quantity",
          value: "quantityUnit",
          groupable: false,
        },
        {
          text: "State",
          value: "status",
          groupable: false,
        },
        {
          text: "Storage",
          value: "storage",
          groupable: false,
        },
      ],
      entitySheetId: null,
      entityDialog: false,
    };
  },

  computed: {
    ...mapGetters(["statuses"]),
  },

  watch: {
    dialog(val) {
      this.resetFormData();
      if (val) {
        // ApiService.get(`sales/salesOrder/${this.salesOrder.id}/entities`).then(
        //   ({ data }) => {
        //     this.entityCollection = data.map((i) => {
        //       i.name = this.$helpers.getTranslated(i.translations).name;
        //       i.quantityUnit = this.$helpers.getTranslated(
        //         i.quantity_unit_translations
        //       ).name;
        //       return i;
        //     });

        //     this.entityCollectionOrigi = [...this.entityCollection];
        //   }
        // );
        ApiService.get(
          `procurement/purchaseOrder/${this.formModel.id}/entities`
        )
          .then(({ data }) => {
            this.entityList = data.map((i) => {
              i.name = this.$helpers.getTranslated(i.translations).name;
              i.quantityUnit =
                i.quantity +
                " " +
                this.$helpers.getTranslated(i.quantity_unit_translations).name;
              return i;
            });
            // this.loadFormData(this.formModel.id);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },
  },

  methods: {
    resetFormData() {
      this.entityCollection = [];
      this.editedItem = null;
    },

    handleDeleteItemConfirm() {
      this.entityCollection.splice(this.editedItem, 1);

      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.editedItem = null;
      this.dialogDelete = false;
    },

    handleSaveEntities() {
      if (this.entityCollection.length > 0) {
        let prevIds = this.entityCollectionOrigi.map((i) => i.item_entity_id);

        let ids = this.entityCollection.map((i) => i.item_entity_id);

        let intersectionIds = prevIds.filter((x) => !ids.includes(x));

        ApiService.post(`sales/salesOrder/${this.salesOrder.id}/entities`, {
          entities: intersectionIds,
        }).then(() => {
          this.handleCloseModalForm();
        });
      }
    },

    handleCloseEntitySheetDialog() {
      this.entityDialog = false;
      this.entitySheetId = null;
    },

    handleCloseModalForm() {
      this.selected = [];
      this.resetFormData();
      this.$emit("handleClosePODetailedListDialog");
    },

    deleteItem(item) {
      this.editedItem = this.entityCollection.indexOf(item);
      this.dialogDelete = true;
    },

    handleOpenEntitySheetDialog(item) {
      this.entitySheetId = item.item_entity_id;
      this.entityDialog = true;
    },
  },
};
</script>
