<template>
  <v-dialog v-model="modalData.dialog" width="1450px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New Purchase Order
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-col cols="12" sm="12" md="12">
          <span class="headline">{{ formTitle }}</span>
          <v-btn
            @click="handleCloseModalForm"
            style="float: right; cursor: pointer"
            icon
            color="#26223c"
          >
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-col>
        <!-- v-if="statusLine" -->
        <v-col cols="12" sm="12" md="12">
          <v-tabs dark background-color="#232341" fixed-tabs>
            <v-tabs-slider color="#232341"></v-tabs-slider>
            <v-tab
              v-for="(i, index) in statuses"
              :key="index"
              :href="'#tab-' + index"
              :disabled="i != formModel.status"
            >
              <span style="color: #fff">{{ $t("ITINER.status_" + i) }}</span>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-container>
            <v-row>
              <v-col cols="2" sm="2" md="2">
                <v-btn
                  v-if="formModel.status == 0 && formModel.items.length > 0"
                  class="button"
                  color="primary mr-2"
                  elevation="2"
                  target="_blank"
                  @click="handleInProgress"
                >
                  In progress
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" sm="2" md="2">
                <v-btn
                  v-if="formModel.id && formModel.items.length > 0"
                  class="button"
                  color="primary mr-2"
                  elevation="2"
                  target="_blank"
                  @click="handleDetailedStatus"
                >
                  Detailed status
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" md="3" class="mt-5">
                <ul>
                  <li>
                    <h4 class="text-lg-right">
                      Total: {{ computedTotalNetValue }}
                      {{ formModel.currency_id }} (Net)
                    </h4>
                  </li>
                  <li>
                    <h4 class="text-lg-right">
                      {{ computedTotalGrossValue }}
                      {{ formModel.currency_id }} (Gross)
                    </h4>
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="3" sm="3" md="3">
                <!-- :disabled="formModel.status > 0" -->
                <v-select
                  v-model="formModel.owner_id"
                  :items="procurementCollection"
                  label="Assigned procurement person"
                  item-text="name"
                  item-value="id"
                  :rules="requiredRules"
                  :error-messages="messages['owner_id']"
                  @keyup="messages['owner_id'] = ''"
                  :disabled="formModel.status > 5"
                />
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-if="storageCollection"
                  v-model="formModel.storage_id"
                  :items="storageCollection"
                  label="Destination storage"
                  item-text="name"
                  item-value="id"
                  :error-messages="messages['storage_id']"
                  @keyup="messages['storage_id'] = ''"
                  :disabled="formModel.id"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="formModel.supplier"
                  :items="computedSupplierCollection"
                  item-text="name"
                  label="Search for supplier"
                  return-object
                  @change="handleChangeSupplier"
                  :disabled="formModel.id"
                >
                </v-autocomplete>
                <table style="width: 100%" v-if="formModel.supplier">
                  <tr>
                    <th>Company</th>
                    <th>Contact person</th>
                    <th>Phone no.</th>
                    <th>E-mail</th>
                  </tr>
                  <tr>
                    <td style="cursor: pointer">
                      <span>{{ formModel.supplier.companyName }}</span>
                    </td>
                    <td style="cursor: pointer">
                      <span>{{ formModel.supplier.contactName }}</span>
                    </td>
                    <td>
                      <p v-if="formModel.supplier.contact_data">
                        {{ formModel.supplier.contact_data.mobile_phone }}
                      </p>
                      <p>
                        {{ formModel.supplier.contact_data.phone_number }}
                      </p>
                    </td>
                    <td>
                      <span>{{ formModel.supplier.email }}</span>
                    </td>
                    <!-- <td>
                      <v-icon small color="red"> mdi-delete </v-icon>
                    </td> -->
                  </tr>
                </table>
                <br />
                TAX number:
                {{ formModel.supplier ? formModel.supplier.vat_number : "" }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="5" md="5">
                <v-select
                  v-if="computedCompanyAddresses"
                  v-model="formModel.addresses"
                  :items="computedCompanyAddresses"
                  label="Choose address"
                  item-text="id"
                  return-object
                  :rules="requiredRules"
                  :error-messages="messages['storage_id']"
                  @keyup="messages['storage_id'] = ''"
                  :disabled="formModel.status > 5"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <label for="datepicker-full-width">Schedule date</label>
                <b-form-datepicker
                  id="datepicker-scheduled-date"
                  v-model="formModel.scheduled_date"
                  :min="new Date(Date.now())"
                  start-weekday="1"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                  :disabled="formModel.id"
                ></b-form-datepicker>
                <small
                  v-if="
                    !messages['scheduled_date'] &&
                    messages['scheduled_date'] != today
                  "
                  style="color: red"
                >
                  {{ messages["scheduled_date"] }}
                </small>
                <div>
                  <a @click.prevent="addDays(15)">+15 days</a>
                  <br />
                  <a @click.prevent="addDays(30)">+30 days</a>
                </div>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  :disabled="formModel.items.length > 0"
                  v-model="formModel.currency_id"
                  :items="currencyCollection"
                  item-text="id"
                  label="Currency"
                  :rules="requiredRules"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-form
                v-model="inputData.formDataValid"
                ref="componentFormData"
                v-if="!formModel.id"
              >
                <v-row>
                  <v-col cols="12" sm="3" md="3">
                    <v-autocomplete
                      style="width: 100%"
                      v-model="inputData.item"
                      :items="itemCollectionWithDefaultQuantities"
                      item-text="ReferenceName"
                      label="Search item"
                      return-object
                      :rules="requiredRules"
                      @change="handleInputDataItemChange"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      label="No. of pcs"
                      type="number"
                      min="0"
                      v-model="inputData.num_of_pcs"
                      :rules="requiredRules"
                      v-on:input="calculatePriceGross"
                      :disabled="!inputData.item"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1">
                    <v-select
                      v-model="inputData.quantity"
                      :items="computedDefaultItemQuantities"
                      label="Quantity"
                      :rules="requiredRules"
                      :id="dynamicID"
                      :disabled="isEditedItemData"
                    />
                  </v-col>

                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      v-if="inputData.item"
                      label="Quantity unit"
                      :value="
                        $store.getters.getQuantityUnitByID(
                          inputData.item.quantity_unit_id
                        ).name
                      "
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      :disabled="!inputData.item"
                      :id="dynamicID"
                      v-model="inputData.price_net"
                      type="number"
                      min="0"
                      label="Net unit price"
                      :rules="requiredRules"
                      v-on:input="calculatePriceGross"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      :disabled="!inputData.item"
                      :id="dynamicID"
                      v-model="inputData.vat_value"
                      label="Tax rate"
                      type="number"
                      min="0"
                      v-on:input="calculatePriceGross"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      :id="dynamicID"
                      v-model="inputData.net_value"
                      label="Net value"
                      type="number"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      :id="dynamicID"
                      v-model="inputData.tax"
                      label="Tax"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1">
                    <v-text-field
                      :id="dynamicID"
                      v-model="inputData.price_gross"
                      label="Price gross"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1">
                    <v-btn
                      :disabled="!inputData.item"
                      color="success"
                      @click="handleAddItemToList"
                      class="ma-1"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <DeleteModalDialog
                  :dialogDelete="dialogDelete"
                  @closeDelete="handleCloseDelete"
                  @deleteItemConfirm="handleDeleteItemConfirm"
                >
                </DeleteModalDialog>
                <v-data-table
                  :headers="computeditemHeader"
                  :items="computedItemCollection"
                  :search="search"
                  :sort-by="['name']"
                  @click:row="handleClickItem"
                  :loading="loadingTable"
                >
                  <template v-slot:item.price_net="{ item }">
                    {{
                      $helpers.getNicePrice(+item.price_net, item.currency_id)
                    }}
                  </template>
                  <template v-slot:item.net_value="{ item }">
                    {{
                      $helpers.getNicePrice(item.net_value, item.currency_id)
                    }}
                  </template>
                  <template v-slot:item.price_gross="{ item }">
                    {{
                      $helpers.getNicePrice(item.price_gross, item.currency_id)
                    }}
                  </template>
                  <template v-slot:item.tax="{ item }">
                    {{ $helpers.getNicePrice(item.tax, item.currency_id) }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      v-if="!formModel.id"
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:body.append>
                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total amount payable:</td>
                      <td></td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            computedTotalNetValue,
                            formModel.currency_id
                          )
                        }}
                      </td>
                      <td></td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            computedTotalGrossValue,
                            formModel.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{ formModel.currency_id }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formModel.notes"
                  rows="5"
                  label="Notes"
                  :disabled="formModel.status > 5"
                ></v-textarea>
              </v-col>
            </v-row>
            <POdetailedListDialog
              :formModel="formModel"
              :dialog="POListDialog"
              @handleClosePODetailedListDialog="POListDialog = false"
            >
            </POdetailedListDialog>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./PurchaseOrders";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import POdetailedListDialog from "@/view/components/POdetailedListDialog.vue";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";

const TRANSLATED_ATRIBUTES = [];

const now = new Date();
let day = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
let month =
  now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;

const today = now.getFullYear() + "-" + month + "-" + day;

export const initialFormData = () => ({
  id: null,
  status: 0,
  translations: [],
  owner_id: null,
  contact_id: null,
  storage_id: null,
  supplier: null,
  notes: null,
  currency_id: "HUF",
  items: [],
  scheduled_date: today,
});

const initialInputData = () => ({
  formDataValid: false,
  item: null,
  quantity: null,
  quantity_unit_id: null,
  num_of_pcs: 1,
  vat_value: 27,
  vat_name: null,
  price_net: 0,
  net_value: 0,
  tax: 0,
  price_gross: 0,
});

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  name: "PurchaseOrderForm",
  components: { POdetailedListDialog, DeleteModalDialog },
  props: [
    "modalData",
    "permissions",
    "storageCollection",
    "procurementCollection",
    "itemCollection",
    "currencyCollection",
  ],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length < 255 || "Nnit name must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      statuses: [0, 5, 10],
      inputData: initialInputData(),

      itemHeader: [
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Num of pcs",
          value: "num_of_pcs",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Quantity unit",
          value: "quantityUnit",
        },
        {
          text: "Unit price",
          value: "price_net",
        },
        {
          text: "Tax rate",
          value: "vat_value",
        },
        {
          text: "Net value",
          value: "net_value",
        },
        {
          text: "Tax",
          value: "tax",
        },
        {
          text: "Gross value",
          value: "price_gross",
        },
        {
          text: "Currency",
          value: "currency_id",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],

      POListDialog: false,
      dialogDelete: false,
      deletableItem: null,
    };
  },
  computed: {
    ...mapGetters(["contactListCollection"]),

    computeditemHeader() {
      if (!this.formModel.id) return this.itemHeader;
      let header = [...this.itemHeader];
      header.pop();
      return header;
      //      12248841
      //      ynQx9wjP
    },

    computedSupplierCollection() {
      return this.contactListCollection.filter(
        (item) => item.companyType && item.companyType.id == 3
      );
    },

    computedCompanyAddresses() {
      if (!this.formModel.supplier) return [];
      let addresses = [];
      let companyAddresses = this.formModel.supplier.companyAddresses;

      companyAddresses.billing.forEach((addr) => {
        if (addresses.indexOf(addr) == -1) {
          addr.id =
            addr.country +
            " " +
            addr.postcode +
            ", " +
            addr.city +
            " " +
            addr.street;
          addresses.push(addr);
        }
      });

      return addresses;
    },

    itemCollectionWithDefaultQuantities() {
      return this.itemCollection.filter((item) => {
        return item.default_quantities.length > 0;
      });
    },

    computedDefaultItemQuantities() {
      if (!this.inputData.item) return [];

      return this.inputData.item.default_quantities;
    },

    computedItemCollection() {
      return this.formModel.items.map((item) => {
        let i = this.$store.getters.getItemByID(item.item_id);
        item.reference = i.reference;
        item.name = i.name;
        item.quantityUnit = this.$store.getters.getQuantityUnitByID(
          i.quantity_unit_id
        ).name;
        item.net_value = this.getFormattedPrice(
          item.num_of_pcs * item.price_net
        );

        item.tax = this.getFormattedPrice(
          (item.net_value / 100) * item.vat_value
        );
        item.currency_id = this.formModel.currency_id;

        return item;
      });
    },

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? "Edit purchase order - " + this.formModel.po_number
        : "New purchase order";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },

    computedTotalNetValue() {
      if (this.computedItemCollection.length == 0) return 0;
      return this.computedItemCollection
        .map((entity) => {
          return entity.net_value;
        })
        .reduce((a, b) => a + b);
    },

    computedTotalGrossValue() {
      if (this.computedItemCollection.length == 0) return 0;
      return this.computedItemCollection
        .map((entity) => {
          return entity.price_gross;
        })
        .reduce((a, b) => a + b);
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.inputData = initialInputData();
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (this.$refs.componentFormData)
          this.$refs.componentFormData.resetValidation();
        if (value.editedId) {
          this.loadFormData(value.editedId);
        } else {
          this.formModel = Object.assign({}, initialFormData());

          // this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchContactList"]),

    loadFormData(id) {
      this.loader = true;
      ApiService.get(ENDPOINT + id)
        .then(({ data }) => {
          this.formModel = Object.assign({}, data);
          this.formModel.supplier = this.contactListCollection.find((item) => {
            return (
              data.contact_id == item.contact_id &&
              data.company_id == item.companyId
            );
          });
          // this.setTranslatedAttributes();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);
      this.$refs.form.validate();

      if (this.formValid) {
        model.company_id = model.supplier.companyId;
        model.contact_id = model.supplier.contact_id;
        this.resetErrorMessages();
        // this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleInputDataItemChange(item) {
      console.log(item);
    },

    fetchModel() {
      this.fetchContactList();
    },

    handleChangeSupplier(supplier) {
      console.log(supplier);
    },

    addDays(days) {
      let newDate = new Date().addDays(days);
      this.formModel.scheduled_date =
        newDate.getFullYear() +
        "-" +
        1 * (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate();
    },

    getFormattedPrice(price) {
      let currency = this.formModel.currency_id;
      if (price == 0 || !price || isNaN(price)) return "";

      if (currency == "HUF") {
        return Math.round(price);
        // return parseInt(price);
      }

      return (1 * price).toFixed(2);
    },

    calculatePriceGross() {
      if (this.inputData.price_net) {
        this.inputData.net_value = this.getFormattedPrice(
          this.inputData.num_of_pcs * this.inputData.price_net
        );
        this.inputData.tax = this.getFormattedPrice(
          (this.inputData.net_value / 100) * this.inputData.vat_value
        );

        this.inputData.price_gross = this.getFormattedPrice(
          this.inputData.net_value + this.inputData.tax
        );

        this.handleCalculateBrutto();
      }
    },

    handleCalculateBrutto() {
      if (this.inputData.price_net) {
        this.inputData.price_gross = this.getFormattedPrice(
          this.inputData.num_of_pcs *
            Number(this.inputData.price_net) *
            (Number(this.inputData.vat_value) / 100 + 1)
        );
      }
    },

    handleAddItemToList() {
      this.$refs.componentFormData.validate();
      if (this.inputData.formDataValid) {
        let item = {
          item_id: this.inputData.item.id,
          quantity: this.inputData.quantity,
          num_of_pcs: this.inputData.num_of_pcs,
          price_net: this.inputData.price_net,
          price_gross: this.inputData.price_gross,
          vat_name: null,
          vat_value: this.inputData.vat_value,
        };
        this.formModel.items.push(item);
        this.inputData = initialInputData();
        this.$refs.componentFormData.resetValidation();
      }
    },

    handleInProgress() {
      ApiService.get(`procurement/purchaseOrder/${this.formModel.id}/start`)
        .then(({ data }) => {
          console.log(data);
          this.loadFormData(this.formModel.id);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleDetailedStatus() {
      this.POListDialog = true;
    },

    deleteItem(item) {
      this.deletableItem = item;
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      this.formModel.items = this.formModel.items.filter(
        (i) => i.item_id != this.deletableItem.item_id
      );
      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.dialogDelete = false;
      this.deletableItem = null;
    },
  },

  mounted() {
    this.fetchModel();
    // this.setTranslatedAttributes();
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
.v-text-field__details {
  color: red !important;
}
</style>
