<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="purchaseOrderCollection"
        :search="search"
        :sort-by="['po_number']"
        sort-desc="true"
        @click:row="handleClickItem"
        :loading="loadingTable"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <PurchaseOrderModalForm
              :modalData="modalData"
              :permissions="permissions"
              :storageCollection="storageCollection"
              :procurementCollection="procurementCollection"
              :itemCollection="itemCollection"
              :currencyCollection="currencyCollection"
              :quantityUnitCollection="quantityUnitCollection"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></PurchaseOrderModalForm>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.price_net="{ item }">
          {{ $helpers.getNicePrice(item.price_net, item.currency_id) }}
        </template>
        <template v-slot:item.status="{ item }">
          <span>
            {{ $t("ITINER.status_" + item.status) }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="permissionCan('delete') && item.status == 0"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import PurchaseOrderModalForm, {
  initialFormData,
} from "./PurchaseOrderModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import ApiService from "@/core/services/api.service";

export const ENDPOINT = "procurement/purchaseOrder/";

export const PERMISSION_TO = "procurement.purchaseOrder.";

export default {
  name: "PurchaseOrder",
  components: { PurchaseOrderModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "purchaseOrder",
      routePath: "/procurement/purchaseOrder/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Purchase orders",
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "PO ID",
          value: "po_number",
        },
        {
          text: "Owner",
          value: "owner_name",
        },
        {
          text: "Company",
          value: "company_name",
        },
        {
          text: "Price net",
          value: "price_net",
        },
        {
          text: "Currency",
          value: "currency_id",
        },

        {
          text: "Scheduled date",
          value: "scheduled_date",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Items",
          value: "itemsShortNames",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),
      endPoint: ENDPOINT,
      dialogDelete: false,
      loadingTable: true,
      procurementCollection: [],
    };
  },

  computed: {
    ...mapGetters([
      "storageCollection",
      "itemCollection",
      "quantityUnitCollection",
      "purchaseOrderCollection",
      "quantityUnitCollection",
      "currencyCollection",
    ]),
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      "fetchProcurement",
      "fetchStorage",
      "fetchItem",
      "fetchPurchaseOrder",
      "fetchQuantityUnit",
      "fetchCurrency",
    ]),

    fetchProcurementCollection() {
      ApiService.get("userGroup/" + process.env.VUE_APP_PROCUREMENT_GROUP_ID)
        .then(({ data }) => {
          this.procurementCollection = data.users;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    fetchModel() {
      return new Promise((resolve) => {
        this.fetchStorage();
        this.fetchProcurementCollection();
        this.fetchItem();
        this.fetchPurchaseOrder();
        this.fetchQuantityUnit();
        this.fetchCurrency();
        resolve();
      });
    },
  },

  async mounted() {
    this.fetchModel().then(() => {
      this.loadingTable = false;
    });
  },
};
</script>
