import { render, staticRenderFns } from "./PurchaseOrders.vue?vue&type=template&id=125a401f"
import script from "./PurchaseOrders.vue?vue&type=script&lang=js"
export * from "./PurchaseOrders.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports